import Vue from 'vue'
import App from './App.vue'
import router from './router'
import global from "@/global";
import utils from "@/common/utils";
import vuetify from './plugins/vuetify'
import VueParticles from 'vue-particles'
import ElementUI from 'element-ui';
import request from './api/index'
import 'element-ui/lib/theme-chalk/index.css';
import dataV from '@jiaminghi/data-view'
import Swal from "sweetalert2";
import './common/css/custom-scrollbar.css'

Vue.use(dataV)
Vue.use(ElementUI);
Vue.use(VueParticles)

Vue.config.productionTip = false
// sweetAlert
Vue.prototype.$swal = Swal
// 发送请求
Vue.prototype.$request = request
Vue.prototype.$globalData = global
// 全局工具类
Vue.prototype.$utils = utils

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
