export default {

  serverIp: process.env.VUE_APP_BASE_URL,

  // 主题颜色
  themeColor: '#1976d2',

  // 分页大小
  pageSize: 16,

  // 响应状态码
  repStatus: {
    success: 1,   // 成功
    fail: -1,     // 失败
    expired: 2    // 会话过期
  },

  // 设备状态
  deviceStatus: {
    unregister: 1,  // 未注册
    unbinding: 2,   // 未绑定
    using: 3        // 使用中
  },

  // 报警记录类型
  alertType: {
    exceed: 1,
    offline: 2,
    powerOff: 3,
    powerOn: 4
  },

  // sim卡状态
  simStatus: {
    waitActivate: 1,  // 待激活
    activated: 2,     // 已激活
    paused: 3,        // 已停卡
    cancelled: 4      // 已销卡
  },

  // 分享状态
  shareType: {
    creator: 1,     // 创建者
    writer: 2,      // 可写
    reader: 3       // 只读
  },

}
