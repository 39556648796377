const phoneRegex = /^1[3456789]\d{9}$/

export default {
  // 手机号码校验
  phoneValidate(phone) {
    return phoneRegex.test(phone)
  },

  // 防抖函数
  debounceFunc(func, delay = 500) {
    // 防抖信号量
    let debounceTimer
    return function (...args) {
      clearTimeout(debounceTimer)
      debounceTimer = setTimeout(() => {
        func.apply(this, args)
      }, delay)
    }
  },

  // 节流函数
  throttleFunc(func, time = 2000) {

  },

  //使用递归的方式实现数组、对象的深拷贝
  deepClone (obj) {
    let objClone = Array.isArray(obj) ? [] : {};
    if (obj && typeof obj === "object") {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          //判断ojb子元素是否为对象，如果是，递归复制
          if (obj[key] && typeof obj[key] === "object") {
            objClone[key] = this.deepClone(obj[key]);
          } else {
            //如果不是，简单复制
            objClone[key] = obj[key];
          }
        }
      }
    }
    return objClone;
  },

  // 获取时间字符串
  getTimeString(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    const second = date.getSeconds().toString().padStart(2, '0');
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  },

  addOrSubtractTime(inputDateStr, timeDimension, operation) {
    const inputDate = new Date(inputDateStr);
    // 根据时间维度和加减标识进行相应的操作
    switch (timeDimension) {
      case 1:
        if (operation === 'add') {
          inputDate.setMinutes(inputDate.getMinutes() + 1);
        } else {
          inputDate.setMinutes(inputDate.getMinutes() - 1);
        }
        break;
      case 2:
        if (operation === 'add') {
          inputDate.setHours(inputDate.getHours() + 1);
        } else {
          inputDate.setHours(inputDate.getHours() - 1);
        }
        break;
      case 3:
        if (operation === 'add') {
          inputDate.setDate(inputDate.getDate() + 1);
        } else {
          inputDate.setDate(inputDate.getDate() - 1);
        }
        break;
      case 4:
        if (operation === 'add') {
          inputDate.setMonth(inputDate.getMonth() + 1);
        } else {
          inputDate.setMonth(inputDate.getMonth() - 1);
        }
        break;
      case 5:
        if (operation === 'add') {
          inputDate.setFullYear(inputDate.getFullYear() + 1);
        } else {
          inputDate.setFullYear(inputDate.getFullYear() - 1);
        }
        break;
    }

    return this.getTimeString(inputDate)
  },


}