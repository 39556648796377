import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 缓存原来的 push 方法
const originalPush = VueRouter.prototype.push
//指定新的push方法
VueRouter.prototype.push = function (location, onResolve, onReject) {
  //制定了一个成功或者失败的回调
  if (onResolve || onReject) {
    //直接调用原来的 push 方法
    // originalPush(location, onResolve, onReject)  //这里使用 call 可以直接指定当前的 router 对象，要是没有这个就会默认是window 对象，启用严格模式之后就是 undefined
    return originalPush.call(this, location, onResolve, onReject)
  }
  //如果没有指明一个成功或者失败的回调，需要使用到catch处理
  return originalPush.call(this, location).catch((err) => {
    //如果是重复导航所引起的错误的话
    if (VueRouter.isNavigationFailure(err)) {
      //这个 return err 返回的是成功的 Promise，其中Promise的value是 err
      return err
    }
    //如果是其他的错误就会接着往下传
    return Promise.reject(err)
  })
}

//缓存原来的replace 方法
const originalReplace = VueRouter.prototype.replace
//指定了一个新的 replace 方法
VueRouter.prototype.replace = function (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalReplace.call(this, location, onResolve, onReject)
  }
  return originalReplace.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      return err
    }
    return Promise.reject(err)
  })
}

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/404',
    name: 'none',
    component: () => import('../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.addRoute({
  path: '/',
  redirect: '/empty',
  component: () => import('../views/Manage.vue'),
  children: [
    {path: '/device/:deviceId', name: '设备', component: () => import('../views/Device.vue'), props: true},
    {path: '/empty', name: '空状态', component: () => import('../views/Empty.vue')},
  ]
})

// 路由守卫
router.beforeEach((to, from, next) => {

  // 前往登录则直接放行
  if (to.path === '/login') {
    return next()
  }

  const token = sessionStorage.getItem("token");
  // 没有token 则跳转到登录页面
  if (!token){
    return next("/login")
  }

  // 若当前路由无法匹配
  if (!to.matched.length) {
    next('/404')
  }

  // 其他情况放行路由
  next()
})

export default router
