import axios from 'axios'
import router from "@/router";
import globalData from "../global";
import {Notification} from 'element-ui';

const request = axios.create({
  // 统一设置URL前缀
  baseURL: `${globalData.serverIp}`,
  // 请求最长响应时间
  timeout: 5000,
  showFail: true,
})

// 请求拦截器
request.interceptors.request.use(config => {
  config.headers['Content-Type'] = 'application/json';
  config.headers['token'] = sessionStorage.getItem("token");
  return config
}, error => {
  return Promise.reject(error)
});


// 响应拦截器
request.interceptors.response.use(response => {
  let res = response.data
  // 判断状态码 错误则给出提醒
  if (res.status !== 1 && response.config.showFail) {
    // 显示错误信息
    if (res.msg !== '设备不存在') {
      Notification({type: 'error', title: '操作失败', message: res.msg})
    }
    if (res.status === -2) {
      // 如果登录信息过期 重置本地存储 前往登录
      sessionStorage.removeItem("token")
      sessionStorage.removeItem("userInfo")
      router.replace("/login")
    }
  }
  // 返回结果
  return res
}, error => {
  if (error.code === 'ECONNABORTED' || error.message === "Network Error" || error.message.includes("timeout")) {
    Notification({type: 'error', title: '请求超时了', message: '当前网络环境较差，请稍候重试'})
  } else {
    Notification({type: 'error', title: '出错了', message: error.message})
  }
  return Promise.reject(error)
});

export default request;