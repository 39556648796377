import Vue from 'vue';
const that = Vue.prototype

export default {

  getLoginTicket() {
    return that.$request.get('/user/loginTicket')
  },

  loginByTicket(options) {
    return that.$request.post('/user/loginByLoginTicket', {
      ticket: options.ticket
    }, {
      timeout: 65000,
      showFail: false
    })
  },

  freshToken() {
    return that.$request.get('/user/renewToken')
  },

  getUserInfo() {
    return that.$request.get('/user')
  }

}