<template>
  <router-view/>
</template>

<script>

  import user from "@/api/user";

  export default {
    name: 'App',

    created() {

      document.title = process.env.VUE_APP_NAME + '数据大屏'

      let token = sessionStorage.getItem('token')
      try {
        if (token && JSON.parse(atob(token)).time) {
          if (parseInt(new Date().getTime()/1000) - JSON.parse(atob(token)).time > 1987200) {
            // 刷新token
            user.freshToken().then(res => {
              if (res.status === 1) {
                sessionStorage.setItem('token', res.data[0].token)
              }
            })
          }
        }
      } catch (e) {
      }
    }
  }


</script>

<style>
/*解决弹窗导致的错位问题*/
body {
  padding-right: 0 !important;
}

</style>
